import React from 'react'
import styled from 'styled-components'

import { CompanyInfo } from '../constants'
import { nbsp } from '../utils'

const Wrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto 0 auto;

  .contacts-wrapper {
    padding: 0 3rem 3rem 3rem;
  }

  p {
    text-align: left;
  }
`

const ContactsPage = () => (
  <Wrapper>
    <div className="contacts-wrapper">
      <h1>Контакты</h1>
      <p>Телефоны:</p>
      <ul>
        <li>{`Красноярск: ${nbsp(CompanyInfo.phone2)}`}</li>
        <li>{`Абакан: ${nbsp(CompanyInfo.phone)}, ${nbsp(
          CompanyInfo.additionalPhone
        )}`}</li>
      </ul>
      <p>
        {`Офис продаж: Республика Хакасия, ${nbsp('г. Абакан')}, ${nbsp(
          'ул. Советская 46'
        )}, ${nbsp('оф. 30Н')}`}
      </p>
    </div>
  </Wrapper>
)

export default ContactsPage
